import { CustomComponentType } from '../../../enums/custom-component-type';

export default [
	{
		key: 'script-conditional',
		weight: -10,
		components: [
			{
				type: CustomComponentType.extendedTextArea,
				input: true,
				label: 'Enter your prompt:',
				key: 'userInput',
				dataSrc: '',
				data: {},
				dataType: 'string'
			},
			{
				type: 'button',
				label: 'Generate',
				key: 'generateButton',
				action: 'custom',
				custom: `const userInput = utils.getComponent(instance.parent.components, 'userInput');
								var mappings = [];
								   utils.eachComponent(instance.options.editForm.components, (component) => 
									{
									 if (component.dataFieldId != null) {
									  mappings.push({label: component.label, id: component.dataFieldId});
									 }  
									}, 
								   true);

								const requestBody = {
									UserPrompt: userInput.dataValue,
									MappingTable: mappings
								};
								
								const calculateValue = utils.getComponent(instance.parent.components, 'calculateValue');
								Formio.makeRequest(undefined, '', 'http://loan-genie-alb-1443357119.us-east-2.elb.amazonaws.com/dynamic-application/open-ai', 'post', requestBody, null)
									.then(response => {
										var cleaned = response.replace(/\`|javascript/g, '');
										calculateValue.setValue(cleaned);
									})
									.catch(error => {
										console.error('Error generating script:', error);
									});`
			},
			{
				components: [
					{
						type: CustomComponentType.extendedTextArea,
						input: true,
						label: 'Generated Script:',
						key: 'calculateValue',
						dataSrc: 'url',
						data: {},
						dataType: 'string',
						editor: 'ace',
						hideLabel: true,
						as: 'javascript'
					}
				]
			}
		]
	}
];
