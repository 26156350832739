
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { OnlinePortalApplicationDocumentRequest } from '@/models';
import {
	SbaForm,
	DynamicApplicationDocumentSetting,
	AutoFactory,
	DynamicApplicationDocumentSettingsService,
	DynamicApplicationGMIFormLink,
	UsableProducts,
	FeatureFlagging
} from '@sageworks/jpi';

import { RouteMixin, ScaleMixin } from '@/mixins';
import { ApplicationWizardCard } from '@/components/application-wizard';
import { DocumentUploadRow, SbaFormDetails, HmdaGmiForm } from '@/components';
import BorrowerNotUploadingReasonModal from '@/components/documents/borrower-not-uploading-reason-modal/borrower-not-uploading-reason-modal.vue';

import { SbaFormUtils, ApplicationNavigationUtils } from '../../../utils';
import { fetchCustomerByIds } from '../../../logic/Customer.service';
import { DateFormatting } from '@sageworks/core-logic';
import { isEmptyObject } from '@/utils/ObjectUtils';
import { ToApplicationTemplateType } from '@/utils/loan-app-type-helper/LoanAppTypeHelper';
import { formIsComplete } from '@/utils/hmda-gmi-api-helper';

@Component({
	components: {
		FontAwesomeIcon,
		ApplicationWizardCard,
		DocumentUploadRow,
		SbaFormDetails,
		BorrowerNotUploadingReasonModal,
		HmdaGmiForm
	}
})
export default class Documentation extends Mixins(RouteMixin, ScaleMixin) {
	// Replicated in institution settings
	defaultDocumentsPageMessage = 'Please provide the following documents...';

	@Prop({ default: null })
	applicationId!: string;

	formDataLoaded = false;

	customers: { [customerId: string]: string } | null = null;

	documentSettings: DynamicApplicationDocumentSetting | null = {};

	shouldMatchGeneralMessage = true;
	enableHmdaGmi = false;

	formLinkIntervalId = 0;
	formLinkTimeoutId = 0;
	intervalLength = 2000;
	timeoutLength = 300000;

	get documentMessage() {
		if (!this.shouldMatchGeneralMessage && this.directStore.state.UserCreatedMessages.DocumentsPageMessageHtml !== null) {
			return this.directStore.state.UserCreatedMessages.DocumentsPageMessageHtml;
		}
		return this.directStore.state.InstitutionSettings.documentsMessage.message ?? this.defaultDocumentsPageMessage;
	}

	get isLender() {
		return this.directStore.getters.User.isLender;
	}

	get itemLabel(): string {
		if (this.hasCommunityLendingSubscription === undefined) {
			return '';
		}
		return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
	}
	get hasCommunityLendingSubscription(): boolean | undefined {
		return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
	}

	get sbaFormsByCustomerId() {
		return this.directStore.getters.SbaForm.sbaFormsByCustomers;
	}

	get hmdaGmiFormsByCustomerId() {
		return this.directStore.getters.HmdaGmi.gmiFormLinksByCustomerId;
	}

	get applicationDocumentRequests() {
		return this.directStore.state.ApplicationDocumentRequest.documentRequests ?? {};
	}

	get isDocumentationLoaded() {
		return !!this.sbaFormsByCustomerId && !!this.applicationDocumentRequests;
	}

	get customerIds() {
		return new Set(
			[...Object.keys(this.sbaFormsByCustomerId), ...this.customerIdsWithDocumentRequests, ...Object.keys(this.hmdaGmiFormsByCustomerId)].map(x =>
				Number(x)
			)
		);
	}

	get customerIdsWithDocumentRequests() {
		return new Set([...Object.keys(this.applicationDocumentRequests ?? {})].map(x => Number(x)));
	}

	get isLoading() {
		return !this.formDataLoaded || !this.customers;
	}

	get hasCustomers() {
		return !isEmptyObject(this.customers);
	}

	get canShowGmiForm() {
		return this.enableHmdaGmi;
	}

	@Watch('customerIds', { immediate: true })
	public async onCustomersWithDocumentationChange() {
		if (!this.isDocumentationLoaded) return;

		const customers = await fetchCustomerByIds(Array.from(this.customerIds));

		this.customers = customers.reduce((obj, customer) => {
			if (!customer.id) return obj;

			obj[customer.id] = customer.name ?? '';
			return obj;
		}, {} as { [customerId: number]: string });
	}

	public uploadOtherDocuments(customerId: number): void {
		const { loanApplication } = this.directStore.state.LoanApplicationData;

		if (!loanApplication?.id || !customerId) {
			return;
		}

		const newDocumentRequest = new OnlinePortalApplicationDocumentRequest({
			customerId,
			loanApplicationId: loanApplication.id,
			dateCreated: DateFormatting.formatDateForJpi(new Date())
		});

		this.directStore.dispatch.ApplicationDocumentRequest.addDocumentRequest(newDocumentRequest);
	}

	public canShowSbaForm(sbaForm: SbaForm) {
		if (!sbaForm.formType) return false;

		return SbaFormUtils.isFormTypeLenderOnly(sbaForm.formType) ? this.isLender : true;
	}

	// eslint-disable-next-line max-lines-per-function
	public async beforeMount(): Promise<void> {
		const loanApplicationId = Number(this.applicationId);
		if (loanApplicationId == null || Number.isNaN(loanApplicationId)) return;

		this.directStore.dispatch.ApplicationDocumentRequest.loadPagedDocumentRequests({ loanApplicationId });
		this.directStore.dispatch.SbaForm.loadSbaForms({ loanApplicationId });
		await this.directStore.dispatch.Application.fetchApplicationById(loanApplicationId);

		this.enableHmdaGmi = await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnableHmdainDya);
		const applicationType = this.directStore.getters.Application.applicationById(loanApplicationId)?.type;

		if (applicationType != null) {
			const documentSettingsService = AutoFactory.get(DynamicApplicationDocumentSettingsService);
			this.documentSettings = (await documentSettingsService.getByApplicationType(applicationType)) ?? {};

			await this.directStore.dispatch.InstitutionSettings.fetchShouldMatchGeneralDocumentsPageMessage({ applicationType: applicationType });
			this.shouldMatchGeneralMessage = this.directStore.getters.InstitutionSettings.shouldMatchGeneralDocumentsPageMessage;
			if (!this.shouldMatchGeneralMessage) {
				await this.directStore.dispatch.UserCreatedMessages.fetchDocumentsPageMessage(ToApplicationTemplateType(applicationType));
			}
		}

		const dataLoadPromises: Promise<any>[] = [
			this.directStore.dispatch.LoanApplicationData.loadLoanApplication({ loanApplicationId: loanApplicationId }),
			this.directStore.dispatch.LoanApplicationMetadata.loadMetadata({ loanApplicationId: loanApplicationId, ignoreFormioProperties: true })
		];

		if (this.enableHmdaGmi) {
			dataLoadPromises.push(this.directStore.dispatch.HmdaGmi.loadGmiForms({ loanApplicationId: loanApplicationId }));
		}

		if (this.directStore.state.DocumentName.documentNames.length === 0) {
			dataLoadPromises.push(this.directStore.dispatch.DocumentName.loadDocumentNames());
		}

		await Promise.all([dataLoadPromises]);
		// document upload depends on form metadata because it must figure out which loans to associate the
		// document to
		await this.directStore.dispatch.MultiLoanApplicationForm.loadAllFormData();
		this.formDataLoaded = true;
	}

	async mounted() {
		await this.directStore.dispatch.UsableProducts.fetchUsableProducts();
	}

	public navigateToApplication() {
		ApplicationNavigationUtils.navigateToApplicationForm(this.$router, this.applicationId);
	}

	public navigateToSubmit() {
		ApplicationNavigationUtils.navigateToApplicationSubmit(this.$router, this.applicationId);
	}

	public async formLinkOpened(formLinkInfo: DynamicApplicationGMIFormLink) {
		clearInterval(this.formLinkIntervalId);
		clearTimeout(this.formLinkTimeoutId);

		// Stop checking after 5 minutes, clear interval
		this.formLinkTimeoutId = setTimeout(() => {
			clearInterval(this.formLinkIntervalId);
		}, this.timeoutLength);

		this.formLinkIntervalId = setInterval(() => this.checkForCompleteForm(formLinkInfo), this.intervalLength);
	}

	// Check if form has been completed and refresh GMI form data
	public async checkForCompleteForm(formLinkInfo: DynamicApplicationGMIFormLink) {
		const isComplete = await formIsComplete(formLinkInfo);
		if (isComplete) {
			clearInterval(this.formLinkIntervalId);
			clearTimeout(this.formLinkTimeoutId);
			const loanApplicationId = Number(this.applicationId);
			await this.directStore.dispatch.HmdaGmi.loadGmiForms({ loanApplicationId: loanApplicationId });
		}
	}
}
