import WizardBuilder from 'formiojs/WizardBuilder';
import { AddCustomFieldComponent } from '../../form-components/custom-field';
import { PopupWrapper } from '../../form-components/popup';
import { convertDataFieldToComponent } from '../../../utils/data-field-metadata/data-field-metadata-utils';
import _ from 'lodash';
import { ContextDataProperty, CustomComponentType, ExternalWizardEventType, LoanRoleCustomComponentTypes } from '../../../enums';
import { getWidgetType } from '../../../utils/form-path-utils';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { FeatureFlagging } from '@sageworks/jpi';

export default class ModifiedWizardBuilder extends WizardBuilder {
	private _showPageTools: boolean | undefined;

	protected get isOpenAiCustomJavascriptGenerationEnabled() {
		const featureFlags = getContextDataValue(this, ContextDataProperty.FeatureFlags) ?? [];
		return featureFlags.includes(FeatureFlagging.FeatureFlagEnum.EnableOpnaiCustomJavascriptGeneration);
	}

	
	public get showPageTools() {
		if (this._showPageTools == null) {
			const widgetType = getWidgetType(this.form.path);
			this._showPageTools = widgetType != null ?
				!LoanRoleCustomComponentTypes.includes(widgetType) : true;
		}

		return this._showPageTools ?? true;
	}

	attach(element: HTMLElement) {
		this.loadRefs(element, {
			addCustomField: 'single',
			searchComponents: 'multiple',
			searchIcon: 'multiple'
		});

		this.addEventListener(this.refs.addCustomField as any, 'click', () => this.addCustomField());

		this.refs.searchComponents.forEach(searchInput => {
			this.addEventListener(searchInput, 'input', this.debouncedSearchComponents(searchInput));
		});

		this.refs.searchIcon.forEach(icon => {
			this.addEventListener(icon, 'click', this.clearSearch(icon));
		});

		return super.attach(element);
	}

	private debouncedSearchComponents(searchInput: HTMLElement) {
		return _.debounce(event => {
			event.stopPropagation();

			this.updateFilteredComponents(searchInput);
		}, 500);
	}

	private updateFilteredComponents(searchInput: HTMLElement) {
		for (const groupKey in this.groups) {
			const group = this.groups[groupKey];
			group.default = false;
		}

		const groupKey = searchInput.getAttribute('data-group-key') as string;
		const group = this.groups[groupKey];
		group.default = true;
		group.searchValue = (searchInput as any).value;
		const searchValue = group.searchValue?.toLowerCase() ?? '';
		for (const componentKey in group.components) {
			const component = group.components[componentKey];
			const componentMatches = component.title?.toLowerCase().includes(searchValue);
			component.hidden = !componentMatches;
		}

		return this.redraw().then(() => {
			this.focusSearch(groupKey);
		});
	}

	private clearSearch(icon: HTMLElement) {
		return (event: Event) => {
			event.stopPropagation();

			const groupKey = icon.getAttribute('data-group-key') as string;
			const group = this.groups[groupKey];
			if (group.searchValue.length > 0) {
				this.refs.searchComponents.forEach(input => {
					if (input.getAttribute('data-group-key') === groupKey) {
						(input as any).value = '';
						return this.updateFilteredComponents(input);
					}
				});
			}
			return Promise.resolve();
		};
	}

	private focusSearch(groupKey: string) {
		this.refs.searchComponents.forEach(input => {
			if (input.getAttribute('data-group-key') === groupKey) {
				input.focus();
				(input as any).setSelectionRange(999, 999);
			}
		});
	}

	private async addCustomField() {
		let addCustomFieldComponent = new AddCustomFieldComponent(
			AddCustomFieldComponent.schema({
				path: this.form.path
			}),
			{ parent: this },
			this.dataValue
		);
		let popupWrapper = new PopupWrapper({}, {}, {}, addCustomFieldComponent);

		const addedField = await popupWrapper.showPopup();

		if (addedField != null) {
			this.emit(ExternalWizardEventType.customFieldAdded, this);
			this.groups.udFields.componentOrder.push(addedField.id);
			this.groups.udFields.components[addedField.id] = {
				key: addedField.id,
				...convertDataFieldToComponent(addedField, { weight: 0 })
			};
			this.redraw();
		}
	}

	saveComponent(component: any, parent: any, isNew: any, original: any) {
		super.saveComponent(component, parent, isNew, original);

		// Update saved components to force delete confirmations after initial save
		this.on(
			'saveComponent',
			(component: any) => {
				if (component.skipRemoveConfirm && Object.values(CustomComponentType).includes(component.type)) {
					delete component.skipRemoveConfirm;
				}
			},
			false,
			true
		);
	}

	editComponent(component: any, parent: any, isNew: boolean, isJsonEdit: boolean, original: any): void {
		if (!this.isOpenAiCustomJavascriptGenerationEnabled) {
			_.set(this.options, `editForm.${component.type}`, [
				{
					key: 'scriptAi',
					hidden: true,
				}
			]);
		}

		super.editComponent(component, parent, isNew, isJsonEdit, original);
	}
}
