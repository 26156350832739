
/* eslint-disable vue/no-mutating-props */
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { OnlinePortalDocumentRequest, OnlinePortalApplicationDocumentRequest, DocumentRequestTypes, BootstrapVueSelectOption } from '@/models';
import DocumentUploadHeader from './document-upload-header.vue';
import { DateFormatting } from '@sageworks/core-logic';
import DocumentUploadPopup from './document-upload-popup.vue';
import { ApplicationDocumentRequest } from '@sageworks/jpi';

export default Vue.extend({
	name: 'DocumentUpload',
	components: { DocumentUploadPopup, FontAwesomeIcon, DocumentUploadHeader },
	props: {
		documentRequest: [OnlinePortalApplicationDocumentRequest, OnlinePortalDocumentRequest]
	},
	data() {
		return {
			uploadedFile: null as File | null,
			uploadInProgress: false,
			showDocumentUploadPopup: false,
			downloadInitiated: false,
			downloadFailed: false,
			downloadFeedbackMessage: '',
			documentNameOptions: [] as BootstrapVueSelectOption[],
			additionalDocumentName: '' // Needs to be a separate variable so the password box will re-render on document name selection.
		};
	},
	computed: {
		isLenderView(): boolean {
			return this.directStore.getters.User.isLender;
		},
		isApplicationDocumentRequest(): boolean {
			return this.documentRequest.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest;
		},
		isAdditionalDocument(): boolean {
			return !this.documentRequest.id || this.documentRequest.id < 0 || !this.documentRequest.dateRequested;
		},
		disableFileInput(): boolean {
			return Boolean(this.documentRequest.dateUploaded) || this.uploadInProgress || Boolean(this.documentRequest.dateWaived);
		},
		disableFileUploadButton(): boolean {
			return Boolean(this.documentRequest) && Boolean(this.documentRequest.documentId);
		},
		uploadedFilename(): string | undefined {
			return (this.documentRequest as ApplicationDocumentRequest).uploadedDocumentFilename;
		}
	},
	async beforeMount() {
		const documentNames = this.directStore.getters.DocumentName.documentNameSelectOptions(this.directStore.getters.User.isLender);

		if (this.isAdditionalDocument) {
			Vue.set(this.documentRequest.request, 'documentNamesId', null);
		}

		this.documentNameOptions = [
			{
				value: null,
				text: 'Select a document name',
				disabled: true
			} as BootstrapVueSelectOption,
			...documentNames
		];
	},
	methods: {
		selectDocumentName() {
			var documentNamesId = this.documentRequest?.documentNamesId;
			if (!documentNamesId) return;
			let names = this.documentNameOptions.filter(function(option) {
				return option.value === documentNamesId;
			});
			if (names.length === 0) return;

			this.documentRequest.documentName = this.additionalDocumentName = names[0]?.text;
		},
		triggerClickOnFileInput(): void {
			// Triggers the click event on the input inside b-form-file, since the wrapper isn't clickable
			((this.$refs.fileInput as Vue).$el.firstElementChild as HTMLElement).click();
		},
		onSelectedFileChange(): void {
			this.showDocumentUploadPopup = true;
		},
		async createDocumentRequest(): Promise<void> {
			// Assign folder id given the selected document name
			let documentName = null;
			if (this.documentRequest.documentNamesId) {
				documentName = this.directStore.getters.DocumentName.documentNameById(this.documentRequest.documentNamesId);
			}
			this.documentRequest.documentName = documentName?.name ?? 'Document';
			this.documentRequest.documentFolderId = documentName?.folderId ?? undefined;
			try {
				var newDocumentRequest = await this.directStore.dispatch.ApplicationDocumentRequest.createApplicationDocumentRequest(
					this.documentRequest as OnlinePortalApplicationDocumentRequest
				);
				this.documentRequest.id = newDocumentRequest.id;
				return Promise.resolve();
			} catch (err) {
				return Promise.reject(err);
			}
		},
		async updateDocumentRequest(): Promise<void> {
			this.documentRequest.dateUploaded = DateFormatting.formatDateForJpi(new Date());
			this.documentRequest.documentId = this.directStore.state.Document.currentDocument!.id;
			(this.documentRequest as ApplicationDocumentRequest).uploadedByUserType = ApplicationDocumentRequest.UploadedByUserTypeEnum.BankCustomerUser;
			(this.documentRequest as ApplicationDocumentRequest).uploadedByUsersId = this.directStore.state.User.user?.id;
			this.documentRequest.documentFolderId = this.directStore.state.Document.currentDocument!.documentFolderId || undefined;
			(this.documentRequest as ApplicationDocumentRequest).uploadedDocumentFilename = this.directStore.state.Document.currentDocument?.fileName ?? '';
			try {
				if (this.isApplicationDocumentRequest) {
					await this.directStore.dispatch.ApplicationDocumentRequest.updateApplicationDocumentRequest(
						this.documentRequest as OnlinePortalApplicationDocumentRequest
					);
				} else {
					await this.directStore.dispatch.DocumentRequest.updateDocumentRequest(this.documentRequest as OnlinePortalDocumentRequest);
				}
				return Promise.resolve();
			} catch (err) {
				return Promise.reject(err);
			}
		},
		resetDocumentRequest() {
			this.documentRequest.dateUploaded = undefined;
			this.documentRequest.documentId = undefined;
			(this.documentRequest as ApplicationDocumentRequest).uploadedDocumentFilename = undefined;
		},
		validateFileUpload(): boolean {
			if (this.isAdditionalDocument && !this.documentRequest.documentNamesId) {
				this.showToast('Document Name Required', 'Select a document name to upload.', 'danger');
				return false;
			}
			return true;
		},
		// eslint-disable-next-line max-lines-per-function
		async uploadFile(password: string): Promise<void> {
			if (!this.validateFileUpload()) return;

			try {
				this.uploadInProgress = true;
				if (this.isAdditionalDocument && !this.documentRequest.id) {
					await this.createDocumentRequest();
				}

				// Always attempt retrieve the documentName by documentNamesId, so we hopefully won't upload documents under the CustomerFacingName
				// Fallback to the documentName set on the request, which likely will be the CFN
				const uploadedDocumentName: string =
					this.directStore.getters.DocumentName.documentNameById(this.documentRequest.documentNamesId || -1)?.name ||
					this.documentRequest.documentName ||
					`Customer_Upload`;

				let uploadError: any = await this.directStore.dispatch.Document.createDocumentFromFile({
					fileToTransform: this.uploadedFile as File,
					documentFolderId: this.documentRequest.documentFolderId,
					associationCustomerId: this.documentRequest.customerId,
					proposedLoanId: this.documentRequest.proposedBankLoansId,
					requestDocumentName: uploadedDocumentName,
					requestDocumentPassword: password
				});

				if (uploadError) {
					let modelErrors = Array.isArray(uploadError.response?.modelErrors) ? uploadError.response?.modelErrors : [];
					let errorMessage = modelErrors.length > 0 ? modelErrors[0].message : null;
					await this.onUploadFailure(errorMessage);
					return;
				}
				await this.updateDocumentRequest();
				this.showDocumentUploadPopup = false;
				this.showToast('Document Upload', 'Document uploaded successfully');
			} catch (err) {
				await this.onUploadFailure();
			} finally {
				this.uploadInProgress = false;
				this.uploadedFile = null;
			}
		},
		async onUploadFailure(uploadFailureReason?: string) {
			this.showToast('Document Upload', uploadFailureReason ?? 'Document not uploaded', 'danger');
			if (this.isAdditionalDocument && this.isApplicationDocumentRequest) {
				await this.deleteApplicationDocumentRequest();
			}
			this.resetDocumentRequest();
		},
		async deleteApplicationDocumentRequest(): Promise<void> {
			return await this.directStore.dispatch.ApplicationDocumentRequest.deleteApplicationDocumentRequest(
				this.documentRequest as OnlinePortalApplicationDocumentRequest
			);
		},
		async deleteDocument(): Promise<void> {
			try {
				if (!this.isApplicationDocumentRequest) return Promise.resolve();
				if (this.isAdditionalDocument) {
					await this.deleteApplicationDocumentRequest();
				} else {
					(this.documentRequest as any).dateUploaded = null;
					(this.documentRequest as any).documentId = null;
					(this.documentRequest as any).uploadedByUserType = null;
					(this.documentRequest as any).uploadedByUsersId = null;
					(this.documentRequest as any).documentFolderId = null;
					await this.directStore.dispatch.ApplicationDocumentRequest.updateApplicationDocumentRequest(
						this.documentRequest as OnlinePortalApplicationDocumentRequest
					);
					return Promise.resolve();
				}
			} catch (err) {
				return Promise.reject(err);
			} finally {
				this.uploadedFile = null;
			}
		},
		async downloadDocument(id: number, templateName?: string): Promise<void> {
			let documentId: number = id;
			this.downloadFeedbackMessage = 'File download in progress...';
			if (this.isApplicationDocumentRequest || documentId === 0) {
				documentId = this.documentRequest.documentId || documentId;
			}
			try {
				this.downloadInitiated = true;
				await this.directStore.dispatch.Document.downloadDocumentById(documentId);
				if (!this.directStore.state.Document.currentDocumentContent) {
					this.downloadFeedbackMessage = 'Download failed. Please try again';
					this.downloadFailed = true;
					return;
				}
				let documentName = templateName ?? this.documentRequest.documentName;
				let hiddenDownloadLink = document.createElement('a');
				hiddenDownloadLink.href = this.directStore.state.Document.currentDocumentContent;
				if (documentName) {
					hiddenDownloadLink.download = documentName;
				}
				hiddenDownloadLink.click();
			} catch (err) {
				this.downloadFeedbackMessage = 'Download failed. Please try again';
				this.downloadFailed = true;
			} finally {
				this.downloadFeedbackMessage = '';
				this.downloadFailed = false;
			}
		},
		showToast(title: string, message: string, variant?: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				noCloseButton: true,
				toaster: 'b-toaster-bottom-right',
				variant: variant
			});
		},
		cancelFileUpload() {
			this.uploadedFile = null;
			this.showDocumentUploadPopup = false;
		}
	}
});
