
import Vue from 'vue';
import { FormioFormOptionsSchema } from '../../formio-interfaces/FormioFormOptionsSchema';
import { OpnRenderType } from '../../enums/opn-render-type';
import TokenHandler from '../../authentication/token-handler';
import { PropType } from 'vue/types/v3-component-props';
import { AutoFactory, DataField, FeatureFlagging, FeatureFlaggingService, IAPIConfiguration, ProductSubscriptionsService, TokenService } from '@sageworks/jpi';
import { OpnPlatformType } from '@sageworks/core-logic';
import { FormioBuilderOptionsSchema, FormioFormSchema } from '@/formio-interfaces';
import { Formio } from 'formiojs';
import { useFormioPlugin } from '../../plugins/formio-plugin';
import { ExternalWizardEventType } from '../../enums';
import { getSuccessOptions, getFailureOptions } from '../../utils/toastr-utils';
import { FormBuilder } from 'vue-formio';

export default Vue.extend({
	name: 'BaseFormBuilderNew',
	components: {
		FormBuilder
	},
	props: {
		formPath: {
			type: String,
			required: true
		},
		subdomain: {
			type: String,
			required: true
		},
		apiUrl: {
			type: String,
			required: true
		},
		properties: {
			type: Array as PropType<DataField[] | undefined>,
			required: false
		},
		templateDataFieldMapping: {
			type: Object as PropType<{ [templateDataFieldId: number]: number } | undefined>,
			required: false
		},
		additionalProperties: {
			type: Array as PropType<DataField[]>,
			required: false
		},
		platformType: {
			type: Number as PropType<OpnPlatformType>,
			required: true
		},
		inSandbox: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loaded: false,
			saving: false,
			showFormValidationErrors: false,
			formValidationErrors: [] as string[],
			formio: null as Formio | null,
			form: null as FormioFormSchema | null,
			featureFlags: [] as FeatureFlagging.FeatureFlagEnum[],
			usableProducts: [] as string[]
		};
	},
	computed: {
		options(): FormioFormOptionsSchema {
			return {
				builder: {
					basic: false,
					advanced: false,
					data: false,
					premium: false,
					resource: false,
					...this.builder
				},
				editors: {
					ckeditor: {
						settings: {
							link: {
								addTargetToExternalLinks: true
							}
						}
					}
				},
				contextData: {
					opnRenderMode: OpnRenderType.Builder,
					featureFlags: this.featureFlags,
				},
				namespace: TokenHandler.GetTokenNamespace(this.subdomain),
				base: this.apiUrl,
				project: this.apiUrl + '/' + this.subdomain,
				templateDataFieldMapping: this.templateDataFieldMapping,
				platformType: this.platformType,
				inSandbox: this.inSandbox,
				...this.additionalOptions
			} as FormioFormOptionsSchema;
		},
		builder(): FormioBuilderOptionsSchema {
			return {} as FormioBuilderOptionsSchema;
		},
		additionalOptions() {
			return {};
		}
	},
	watch: {
		async formPath() {
			this.loaded = false;
			await this.initFormio();
			this.loadForm().then((form: FormioFormSchema) => this.setForm.bind(this, form)());
		}
	},
	async beforeMount() {
		const featureFlaggingService = AutoFactory.get(FeatureFlaggingService);
		this.featureFlags = (await featureFlaggingService.getActiveFeatures()) as FeatureFlagging.FeatureFlagEnum[];
		const productSubscriptionsService = AutoFactory.get(ProductSubscriptionsService);
		this.usableProducts = await productSubscriptionsService.getAllUsableProductsForCurrentUser();

		Formio.use(useFormioPlugin());

		await TokenHandler.VerifyOrGetToken(this.subdomain);
		await this.initFormio();
		const form = await this.loadForm();
		this.setForm(form);
	},
	methods: {
		async initFormio() {
			const tokenService: TokenService = AutoFactory.get(TokenService);
			const apiConfiguration: IAPIConfiguration = AutoFactory.get('IAPIConfiguration');
			Formio.baseUrl = this.apiUrl;
			Formio.projectUrl = this.apiUrl + '/' + this.subdomain;
			Formio.namespace = TokenHandler.GetTokenNamespace(this.subdomain);
			(Formio as any).apiToken = (await tokenService.get()).access_token;
			(Formio as any).apiUrl = apiConfiguration.basePath;

			this.formio = new Formio(this.apiUrl + '/' + this.subdomain + '/' + this.formPath, this.options);
		},
		hasProduct(product: string): boolean {
			return this.usableProducts.some(x => x === product);
		},
		loadForm(): Promise<FormioFormSchema> {
			if (this.formio) {
				return this.formio.loadForm(undefined);
			}

			return Promise.resolve({} as FormioFormSchema);
		},
		setForm(_form: FormioFormSchema) {
			this.form = _form;
			this.loaded = true;
		},
		noop() {
			return false;
		},
		editChild(component: FormioFormSchema) {
			this.$emit('edit-child', component);
		},
		onFormChange(changes: any) {
			if (changes.type != null && changes.type === 'form') {
				this.$emit('template-dirty', true);
			}
		},
		customFieldAdded() {
			this.$emit(ExternalWizardEventType.customFieldAdded);
		},
		async submit() {
			this.formValidationErrors = this.validateForm();
			this.showFormValidationErrors = this.formValidationErrors != null && this.formValidationErrors.length > 0;

			if (this.formio && !this.showFormValidationErrors) {
				this.saving = true;
				if (this.form?.modified !== undefined) {
					this.form.modified = undefined;
				}
				await this.formio
					.saveForm(this.form)
					.then((result: FormioFormSchema) => {
						this.form!.modified = result.modified;
						this.$root.$bvToast.toast(`${this.form!.title} saved successfully`, getSuccessOptions());
						this.$emit('template-dirty', false);
					})
					.catch(() => {
						this.$root.$bvToast.toast(`${this.form!.title} save was not successful`, getFailureOptions());
					})
					.finally(() => {
						this.saving = false;
					});
			}
		},
		cancel() {
			this.$emit('cancel');
		},
		validateForm(): string[] {
			return [];
		}
	}
});
